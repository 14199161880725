import { Box, Container, Grid, Hidden } from '@mui/material'
import { graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import StickyBox from "react-sticky-box"
import HeroBanner from '../components/HeroBanner'
import Issue from '../components/Issue'
import Layout from '../components/Layout'
import { DesktopTableOfContents, NativeTableOfContents } from '../components/platform/TableOfContents'
import { withHelmet } from '../helpers/metadataHelpers'

class PlatformPageTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleIssues: ['climate-change']
    };
  }

  updateVisibilityChange = (anchor, isVisible) => {
    this.setState({
      visibleIssues:
        isVisible ?
        this.state.visibleIssues.concat(anchor) :
        this.state.visibleIssues.filter((issue) => issue !== anchor )
    });
  }

  render() {
    const {
      title,
      introBlurb,
      issues
    } = this.props;

    const classes = {}

    return (
    <Box>
      <HeroBanner title={title} />
      <Container sx={{ paddingY: { xs: 4, sm: 6 }}}>
        <Grid container spacing={4} id="top">
          <Hidden smDown>
            <Grid item xs={12} sm={3}>
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DesktopTableOfContents activeIssue={this.state.visibleIssues[0]} issues={issues} />
              </StickyBox>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={9} className={classes.content}>
            <Hidden smUp>
              <NativeTableOfContents issues={issues}  />
            </Hidden>
            {issues.map((issue, index) => {
              return <Issue key={index} updateVisibilityChange={this.updateVisibilityChange} {...issue} />
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
    )
  }
}

const PlatformPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  const filteredIssues = frontmatter.issues
    .filter(issue => issue.hidden !== true)
    .map(issue => ({...issue, anchor: `${issue.anchor || kebabCase(issue.name)}`}));

  return (
    <Layout>
      <PlatformPageTemplate
        title={frontmatter.title}
        introBlurb={frontmatter.intro_blurb}
        issues={filteredIssues}
      />
    </Layout>
  )
}

PlatformPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

PlatformPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default withHelmet(PlatformPage)

export const PlatformPageQuery = graphql`
  query PlatformPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pagename
        title
        description
        intro_blurb
        issues {
          name
          anchor
          text
          hidden
          summary
        }
      }
    }
  }
`
