import React from 'react'
import PropTypes from 'prop-types'
import MarkdownContent from '../components/MarkdownContent'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import VisibilitySensor from 'react-visibility-sensor';

import { Alert, AlertTitle, Button, Stack } from '@mui/material';

const Summary = ({ content }) => (
  <Box>
    <Alert severity="info" margin={0}>
      <AlertTitle>Summary</AlertTitle>
      <MarkdownContent content={content} styles={{ '& p': { margin: 0 }}} />
    </Alert>
  </Box>
)

function onChange(isVisible, updateVisibilityChange, anchor) {
  // console.log(`Element ${anchor} is now %s`, isVisible ? 'visible' : 'hidden');

  updateVisibilityChange(anchor, isVisible)
}

class Issue extends React.Component {
  render() {
    const { name, image, summary, text, anchor, updateVisibilityChange } = this.props;

    return (
      <VisibilitySensor partialVisibility={true} onChange={(isVisible) => onChange(isVisible, updateVisibilityChange, anchor)}>
        <Box id={anchor} marginBottom={8}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h4" component="h2">{name}</Typography>
            {summary && <Summary content={summary} />}
            <MarkdownContent content={text} />
            <Box textAlign="right">
              <Button href="#top" color="third" size="small" variant="outlined" endIcon={<ArrowUpwardIcon />}>Back to top</Button>
            </Box>
          </Stack>
        </Box>
      </VisibilitySensor>
    )
  }
}

Issue.propTypes = {
  anchor: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
}

export default Issue
