import { InputLabel, NativeSelect, Paper, Stack } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import React from 'react';

const activeIssueSx = {
  borderLeft: '4px solid',
  borderColor: 'primary.main',
}

const listItemParams = {
  fontWeight: "fontWeightBold"
}

export const DesktopTableOfContents = ({ issues, activeIssue }) => (
  <List dense>
    <ListItem disablePadding>
      <ListItem>
        <ListItemText>
            <Typography variant="body2" fontWeight="fontWeightBold">Topics</Typography>
        </ListItemText>
      </ListItem>
    </ListItem>
    { issues.map((issue) => {
      const isActiveIssue = issue.anchor === activeIssue
      return (
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href={`#${issue.anchor}`}
            sx={isActiveIssue ? activeIssueSx : {}}>
            <ListItemText
              primary={issue.name}
              primaryTypographyProps={isActiveIssue ? listItemParams : {}}
            />
          </ListItemButton>
        </ListItem>
      )})}
  </List>
)

export const NativeTableOfContents = ({ issues }) => {
  const handleChange = (event) => {
    window.location.replace(event.target.value)
  };

  return (
    <Paper elevation={0}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="stretch"
        textAlign="center"
        justifyContent="center"
        spacing={{ xs: 0, sm: 2 }}
        marginBottom={5}
      >
        <InputLabel
          htmlFor="select-topic"
          sx={{alignItems: 'center', display: { xs: 'inline-block', sm: 'flex' }}}
          >
          <Typography variant="overline">Select a topic:</Typography>
        </InputLabel>
        <NativeSelect
          onChange={handleChange}
          variant='outlined'
          input={<OutlinedInput/>}
          id="select-topic"
          size="small"
        >
          { issues.map((issue) => {
            return (
              <option key={issue.anchor} value={`#${issue.anchor}`}>{issue.name}</option>
            )})}
        </NativeSelect>
      </Stack>
    </Paper>
  )
}
